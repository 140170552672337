<template>
  <div
    class="mm-images-wall"
    :class="{ 'mm-images-wall--vertical': orientationVertical }"
    :style="{
      '--duration': `${duration}s`,
      '--mask-direction': maskDirection
    }"
  >
    <div
      v-for="(row, rowIndex) in imagesRows"
      :key="rowIndex"
      class="mm-images-wall-marquee"
      :class="{
        'mm-images-wall-marquee--reverse': rowIndex % 2 != 0,
      }"
    >
      <div
        v-for="(rowCopy, rowCopyIndex) in [row, row]"
        :key="rowCopyIndex"
        :aria-hidden="(rowCopyIndex % 2) != 0"
        class="mm-images-wall-marquee__group"
      >
        <div
          v-for="(image, imageIndex) in rowCopy"
          :key="imageIndex"
          class="mm-images-wall__item-wrapper"
        >
          <slot
            name="image"
            :image="image"
            :row-index="rowIndex"
            :image-index="imageIndex"
          >
            <picture v-if="isPictureView(image)">
              <source
                v-for="(source, index) in getPictureSources(image)"
                :key="index"
                :media="source.media"
                :srcset="source.srcset"
              >
              <BaseImg
                :src="image.src"
                format="webp"
                loading="lazy"
                :srcset="image.srcset"
                :preload="false"
              />
            </picture>
            <BaseImg
              v-else
              :src="image.src"
              format="webp"
              loading="lazy"
              :srcset="image.srcset"
              :preload="false"
            />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IImagesWallItem, IImagesWallPictureSource } from '../models/imagesWall.model';
import BaseImg from './BaseImg.vue';

const props = withDefaults(
  defineProps<{
    // Массив изображений
    images: Array<IImagesWallItem>;
    // Ориентация потока изображений
    orientation: 'vertical' | 'horizontal';
    // Количество линий
    lines: number;
    // Скорость прокрутки в секундах
    duration: number;
  }>(),
  {
    orientation: 'horizontal',
    lines: 2,
    duration: 180,
  },
);

const pictureSourceMediaFunctionMap = new Map<string, (srcset: string) => IImagesWallPictureSource>([
  ['srcset1280', getPictureSource1280],
  ['srcset768', getPictureSource768],
  ['srcset360', getPictureSource360],
]);

const imagesRows = computed(() => {
  const separatedImages = [];
  const separateCount = Math.ceil(props.images.length / props.lines);
  for (let i = 0; i < props.lines; i++) {
    separatedImages.push(props.images.slice(separateCount * i, separateCount * (i + 1)));
  }

  return separatedImages;
});

const orientationVertical = computed(() => props.orientation === 'vertical');
const maskDirection = computed(() => orientationVertical.value ? 'to bottom' : 'to right');

function isPictureView(image: IImagesWallItem): boolean {
  return !!(image.src
    && (image.srcset360 || image.srcset768 || image.srcset1280));
}

function getPictureSources(image: IImagesWallItem): Array<IImagesWallPictureSource> {
  return Object.entries(image).map(([key, value]) => (
    pictureSourceMediaFunctionMap.get(key)?.(value) || null
  )).filter((source) => source);
}

function getPictureSource1280(srcset: string): IImagesWallPictureSource {
  return {
    media: '(max-width: 1599px)',
    srcset,
  };
}

function getPictureSource768(srcset: string): IImagesWallPictureSource {
  return {
    media: '(max-width: 1279px)',
    srcset,
  };
}

function getPictureSource360(srcset: string): IImagesWallPictureSource {
  return {
    media: '(max-width: 767px)',
    srcset,
  };
}
</script>

<style lang="scss" scoped>
.mm-images-wall {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto;

  &-marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 8px;
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 5%,
      hsl(0 0% 0% / 1) 95%,
      hsl(0 0% 0% / 0)
    );

    &__group {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 8px;
      min-width: 100%;
      animation: scroll-x var(--duration, 60s) linear infinite;
    }

    &--reverse &__group {
      animation-direction: reverse;
      animation-delay: -3s;
    }
  }

  &__item {
    &-wrapper {
      border-radius: 8px;
      overflow: hidden;
      width: 246px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;

      * {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--vertical {
    flex-direction: row;
    max-height: 800px;
    justify-content: center;
  }

  &--vertical &-marquee,
  &--vertical &-marquee__group {
    flex-direction: column;
  }

  &--vertical &-marquee__group {
    animation-name: scroll-y;
  }
}

@media (prefers-reduced-motion: reduce) {
  .mm-images-wall-marquee__group {
    animation-play-state: paused;
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 8px));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - 8px));
  }
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@media only screen and (max-width: 1599px) {
  .mm-images-wall {
    &__item {
      &-wrapper {
        width: 196.8px;
        height: 112px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-images-wall {
    &__item {
      &-wrapper {
        width: 237.214px;
        height: 135px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-images-wall {
    &__item {
      &-wrapper {
        width: 156px;
        height: 90px;
      }
    }
  }
}
</style>
